import {Component, OnInit, Input} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';
import {FileService} from 'src/app/shared/services/file.service';
import { MessageService } from '../author-design/message.service';

@Component({
  selector: 'app-custom-textbox',
  templateUrl: './custom-textbox.component.html',
  styleUrls: ['./custom-textbox.component.css']
})
export class CustomTextboxComponent implements OnInit {


  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  topHeaders: string[] = ['channelGrouping', 'acquisition', 'behavior'];
  displayedColumns: string[] = ['totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  alldisplayedColumns: string[] = ['channelGrouping', 'totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  dataSource: MatTableDataSource<any>;
  @Input() data: any;
  index: string;
  choice_name: string;
  value: string;
  field_name:string;
  field_choices: any[];

  constructor(
    private router: Router,
    private fileService: FileService,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
     // let today = new Date();
    //  console.log(`data1=${JSON.stringify(this.data)}`);
     if(this.data != undefined){
      console.log(`data=${JSON.stringify(this.data)}`);
        this.index = this.data.index;
        this.value = this.data.value;
        this.field_name = this.data.field_name;
        this.choice_name = this.data.choice_name;
        this.field_choices = this.data.field_choices;
     }
    const currentDate = new Date();
    const dateString = currentDate.toISOString();
     let today = new Date(dateString);
     var lastMonth = new Date(today);
     lastMonth.setDate(lastMonth.getDate() - 30);
     this.start_date = lastMonth.toISOString().slice(0, 10);
     this.end_date = today.toISOString().slice(0, 10);
    this.dataSource = new MatTableDataSource();
    // this.getData(this.start_date, this.end_date);
  }

  updateValue(source: string, event: any) {
    const enteredValue = event.target.value;
    
    if(source == 'option'){
      this.choice_name = enteredValue;
    }
    else if(source == 'percentage'){
      this.value = enteredValue;
    }
    console.log(`source=${source}`);
    console.log(`choice_name=${this.choice_name}`);
    console.log(`value=${this.value}`);

    // If both the select's option and value are set, send the message
    if(this.choice_name != undefined && (this.value != undefined && this.value != '')){
      this.messageService.sendMessage(
        {
          type: 'extra_fields',
          index: this.index,
          field_choices: this.field_choices,
          field_name: this.field_name,
          choice_name: this.choice_name,
          value: this.value,
          data: {
            index: this.index,
            field_name: this.field_name,
            choice_name: this.choice_name,
            value: this.value,
            field_choices: this.field_choices,
          }
        }
      );
    }
  }

  removeItem(field_name: string, choice_name: string, index: string){
    this.messageService.sendMessage(
      {
        type: 'extra_fields_remove_item',
        index: index,
        field_name: field_name,
        choice_name: choice_name
      }
    );
  }

}

